<template>
    <ul class="upload-items">
        <template v-for="(item,idx) in imageList">
            <li
                class="upload uploaded"
                :key="idx"
                v-if="item"
            >
                <!-- 把有数据的放到前面 -->
                <span class="icon">
                    <img :src="$img.upload_icon2" />
                </span>

                <div class="upload-txt">
                    <h4 class="name">{{ item.name }}</h4>
                    <p>
                        <strong class="size">{{item.size/1000 | numFilter}}MB</strong>
                        <span class="date">{{ item.ctime | formatdata('yyyy-MM-dd')}}上传</span>
                    </p>
                </div>
                <div class="opera">
                    <!-- 需要在自定义回调中传入额外的参数，用bind绑定，null为固定，代表不改变原有的this,后面为需要传的参数，可以是对象，和字符串等 -->
                    <el-upload
                        class="file-uploader"
                        :ref="'upload'+idx"
                        :action="action"
                        :headers="imgHeaders"
                        :show-file-list="false"
                        :on-success="on_success.bind(null, idx)"
                        :before-upload="uploadBeforeEvent"
                    >
                        <div class="tips">替换</div>
                    </el-upload>
                    <el-button @click="delimg(idx)">删除</el-button>
                </div>
            </li>
            <li
                class="upload"
                :key="idx"
                v-else
            >
                <el-upload
                    :ref="'upload'+idx"
                    class="file-uploader"
                    :action="action"
                    :show-file-list="false"
                    :on-success="on_success.bind(null,idx)"
                >
                    <div class="upload-txt">
                        <span class="icon">
                            <img :src="$img.upload_icon1" />
                        </span>
                        <strong>点击上传</strong>
                    </div>
                </el-upload>
            </li>
        </template>
    </ul>
</template>
<script>
export default {
    props: ['value'],
    computed: {
        imgHeaders: function () {//图片上传头部信息
            return {
                token: sessionStorage.getItem("token"),
                timestamp: new Date().getTime(),
                secret: this.websiteConfigs.secret
            };
        },
    },
    data() {
        return {
            action: "",
            imageList: this.value,
            headers: {},
            limitLength: 3
        };
    },
    created() {
        var token = sessionStorage.getItem("token");
        this.action = this.websiteConfigs.baseUrl + this.websiteConfigs.uploadUrl + "?group=user";
        this.imageList = this.resolvedImgDatas();
        this.$emit("input", this.imageList);
    },
    methods: {
        on_progress(event, file, fileList) { },
        on_success(obj, res, file, fileList) {
            this.$set(this.imageList, obj, res.data);
            this.$emit("input", this.imageList);
            this.$emit("onupload", this.imageList);
        },
        resolvedImgDatas() {
            var tempArr = [];
            for (let i = 0; i < this.limitLength; i++) {
                if (this.value[i]) {
                    tempArr.push(this.value[i]);
                } else {
                    tempArr.push("");
                }
            }
            return tempArr;
        },
        delimg(idx) {
            this.$set(this.imageList, idx, '');
            this.$emit("input", this.imageList);
        },

        //附件上传前事件
        uploadBeforeEvent(file, x) {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "gif" == suffix ||
                    "zip" == suffix || "rar" == suffix || "7z" == suffix ||
                    "pdf" == suffix || "doc" == suffix || "docx" == suffix || "ppt" == suffix || "pptx" == suffix || "xls" == suffix || "xlsx" == suffix
                ) { } else {
                    this.$message.error(
                        "上传文件格式不正确，请上传jpg，jpeg，png，gif，zip，rar，7z，doc，docx，ppt，pptx，xls，xlsx，pdf等格式的文件"
                    );
                    return false;
                }
            }

            this.imgHeaders.timestamp = new Date().getTime();
        },
    },

};
</script>
<style lang="less" scorped>
.upload-items .upload.uploaded .upload-txt .name {
    width: 315px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
