<template>
    <div>
        <div
            class="appeal-main"
            style="background:#fff;width:100%;padding:0 50px;"
            v-loading="loading"
        >
            <h4 class="title">填写调解内容及上传证明材料</h4>
            <el-form class="appeal-form" style="margin-top:0px !important;">
                <el-form-item label="调解内容">
                    <el-input type="textarea" placeholder="您可以在这里填写您调解的具体原因及诉求" maxlength="1000" v-model="formData.content"></el-input>
                    <p class="blue" @click="tipsDialog = true" style="line-height: 22px;font-size: 10px;margin-top:5px;"><i class="el-icon-question"></i>&nbsp;如何填写项目调解内容？</p>
                    <p class="blue" @click="tipsAppeal = true" style="line-height: 22px;font-size: 10px;"><i class="el-icon-question"></i>&nbsp;了解如何发起调解，以及调解流程，点这里</p>
                </el-form-item>
                <el-form-item>
                    <el-row>
                        <el-col :span="4">上传证明：</el-col>
                        <el-col :span="20">
                            <p class="tips">为了帮您更好地解决问题，请务必上传证明材料(<span>上传图片证明格式为JPG/JPEG/BMP/PNG，容量限制为10M</span>)</p>
                            <multi-upload v-model="formData.files" @onupload="upLoaded"></multi-upload>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="checked">
                        我了解并同意
                    </el-checkbox>
                    <a style="cursor:pointer;color:#409EFF;" @click="goArticleDetail(2)">《询龙网平台服务协议》</a>
                </el-form-item>
                <el-form-item>
                    <el-button @click="save">提交</el-button>
                    <el-button @click="goBack">返回</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-dialog title="如何填写调解内容？" :visible.sync="tipsDialog" width="800" center>
            <div class="mediate">
                <p>调解内容，是询龙网第三方专家对项目调解判断的依据。</p>
                <p>请使用客观的文字对项目中出现的问题进行陈述，并说明自身的诉求。</p>
                <p>所填写的调解内容需包含：</p>
                <p><span>1、申请依据：</span>即项目中出现问题的陈述</p>
                <p><span>2、争议资金分配诉求：</span>即您对争议资金如何分配的诉求</p>
                <p style="margin: 2em 0 2em 0;"><span>填写模板：您可以复制此模板，粘贴到输入框内，并将标有下划线的文字替换为您自己的调解内容</span></p>
            </div>
            <div class="mediate-line"></div>
            <div class="mediate-info">
                <p><span>1、申请依据：</span></p>
                <p class="text-line text-line-decoration">
                    本人（本公司）对****项目提起调解申请，请平台对争议酬金和履约保证金进行调解分配。原定成果提交时间为2020年6月16日，但工程极客超过时限后迟迟未能提交成果，多次沟通工程极客未得到回复。今天已是2020年6月20日，希望能退还已托管的酬金。双方聊天记录见附件截图。
                </p>
                <p><span>2、争议资金分配诉求：</span></p>
                <p>争议酬金总额：<i class="text-line">1000</i>元</p>
                <p>退还雇主的争议酬金：<i class="text-line">1000</i>元</p>
                <p>工程极客获得的争议酬金：<i class="text-line">0</i>元</p>
                <p>履约保证金总金额：<i class="text-line">200</i>元</p>
                <p>退还工程极客的履约保证金：<i class="text-line">200</i>元</p>
                <p>雇主获得的履约保证金：<i class="text-line">0</i>元</p>
                <p>*备注：工程极客获得的争议酬金包含对应的平台服务费</p>
            </div>
            <div class="mediate-line"></div>
        </el-dialog>

        <el-dialog title="调解流程说明" :visible.sync="tipsAppeal" width="800" center>
            <p style="text-align: center;">
                <img :src="$img.appeal_desc" style="max-width:100%;margin: auto;padding:auto;"/>
            </p>
        </el-dialog>

        <!-- ===============================协议条款弹出框=================================  -->
        <el-dialog :title="articleTitle"
                   :visible.sync="dialogAgreement">
            <div v-html="articleContent"></div>
        </el-dialog>
    </div>
</template>
<script>
import multiUpload from "@/components/upload/multi-upload.vue";
import UploadAnnex from "@/components/upload/UploadAnnex.vue";
export default {
    name: "appeal",
    metaInfo() {
        return {
            title: "填写调解内容及上传证明材料 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        multiUpload,
        UploadAnnex
    },
    data() {
        return {
            tipsDialog: false,
            tipsAppeal: false,
            checked: true,
            imgLists: [],
            loading: false,
            formData: {
                content: "",
                files: ""
            },
            dialogAgreement: false, //协议是否显示
            articleContent:'',
            articleTitle:''
        };
    },
    methods: {
        //获取文章详情
        goArticleDetail(e) {
            let _this = this;
            _this.post("/article/index/detail", { id: e }, function (res) {
                if (res.code == 200) {
                    _this.articleContent = res.data.content;
                    if (e == 2) {
                        _this.articleTitle = "询龙网平台服务协议";
                    }
                    if (e == 3) {
                        _this.articleTitle = "询龙网平台交易规则";
                    }
                    _this.dialogAgreement = true;
                }
            });
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 10MB!");
            }
            return isJPG && isLt2M;
        },

        submitBtn() {
            this.$router.push("/employeeuc/todos/delivered"); //工程极客的跳转至工程极客 雇主的跳转至雇主
        },

        upLoaded(files) {
            // 图片上传成功后 files 上传成功后的图片列表
        },

        //提交调解内容
        save() {
            var _this = this;
            
            if (_this.formData.content == "") {
                _this.$message.error("调解内容不能为空");
                return false;
            }
            if (_this.formData.files == ",,") {
                _this.$message.error("调解证明必须上传");
                return false;
            }
            _this.loading = true;
            _this.post(
                "/task/task/task_appeal",
                {
                    tk: _this.$route.query.tk,
                    content: _this.formData.content,
                    files: _this.formData.files
                },
                function(res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.$message.success("提交成功");
                        _this.$router.push(
                            "/user/task/appeal_detail?tk=" +
                                _this.$route.query.tk
                        );
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("提交失败");
                        }
                    }
                }
            );
        },

        //返回
        goBack() {
            let back_page = sessionStorage.getItem("back_page");
            if (back_page) {
                this.$router.push(back_page);
            } else {
                this.$router.go(-1); //返回上一层
            }
        }
    },

    created() {
        // if (!this.$route.query.task_id){
        //   this.$message.error("参数有误");
        //   this.$router. ush('/');//工程极客的跳转至工程极客 雇主的跳转至雇主
        // }
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/todos.less";

.tipsDialog-appeal table tr td {
    background:#ffffff;
    border-width: 1px;
    padding: 8px;
    border-style: solid;
    border-color: #999999;
}

.tipsDialog-appeal table {
    border-collapse: collapse;
    border-spacing: 0px;
    width: 100%;
}

.mediate p {
    text-indent: 2em;
}
.mediate p span,.mediate-info p span {
    font-weight: 600;
}
.mediate-line {
    width: 100%;
    height: 0.5em;
    background:
        linear-gradient(135deg, transparent, transparent 45%, #000000, transparent 55%, transparent 100%),
        linear-gradient(45deg, transparent, transparent 45%, #000000, transparent 55%, transparent 100%);
    background-size: 1em 1em;
    background-repeat: repeat-x, repeat-x;
}
.text-line {
    text-decoration:underline;
}
.text-line-decoration {
    text-indent: 2em;
}
</style>


